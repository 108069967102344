import axios from "axios";
import InventoryItemData from "../data/InventoryItemData";

class CraftingApi {

    public static craftItems(items: (InventoryItemData | null)[]) {
        const apiUrl = "https://universal-crafting-table.dylanpdx.workers.dev/craft";
        //const apiUrl = "http://localhost:8787/craft";
        return axios.get(apiUrl, {
            params: {
                item1: items[0]?.name,
                item2: items[1]?.name,
                item3: items[2]?.name,
                item4: items[3]?.name,
                item5: items[4]?.name,
                item6: items[5]?.name,
                item7: items[6]?.name,
                item8: items[7]?.name,
                item9: items[8]?.name,
            }
        })
    }

}

export default CraftingApi;