import React, { createContext, useContext, useState } from 'react';
import InventoryItemData from './InventoryItemData';

// Define the type for the context
type InventoryContextType = {
    inventory: InventoryItemData[];
    addItem: (item: InventoryItemData) => void;
};

// Default inventory data
const defaultInventory: InventoryItemData[] = [
    {name: 'Wood Log',emojis: ['🪵']},
    //{name: 'Wooden Stick',emojis: ['🪵','𓆱']},
    {name: 'Stone',emojis: ['🪨']},
    {name: 'Water',emojis: ['💧']},
    {name: 'Lava',emojis: ['🔥']},
    {name: 'Dirt',emojis: ['🟫']},
    {name: 'Grass',emojis: ['🌿']},
    /*{name: 'Health Potion',emojis: ['❤️']},
    {name: 'Iron Sword',emojis: ['⚔️', '🗡️']},
    {name: 'Wooden Shield',emojis: ['🛡️','😊','✨']},
    {name: 'Mana Potion',emojis: ['💧','✨','😲','⬆️']},*/
];

// Create the context
const InventoryContext = createContext<InventoryContextType>({
    inventory: defaultInventory,
    addItem: (item: InventoryItemData) => {
        // check if the item is already in the inventory
        if (defaultInventory.find((i)=>i.name.toLowerCase() === item.name.toLowerCase())) {
            return false;
        }
        // add the item to the inventory
        defaultInventory.push(item);
        return true;
    },
});

// Create a custom hook to access the context
export const useInventory = () => useContext(InventoryContext);