import React, { FC } from 'react';
import styles from './CraftingGrid.module.css';
import GridItem from '../GridItem/GridItem';
import InventoryItemData from '../../data/InventoryItemData';

interface CraftingGridProps {
  craftGrid: (InventoryItemData | null)[];
  onGridItemClick: (index : number,button : number) => void;
}

const CraftingGrid: FC<CraftingGridProps> = (props) => (
  <div className='CraftingGrid'>
      <GridItem onClick={(b)=>{props.onGridItemClick(0,b)}} item={props.craftGrid[0]}/>
      <GridItem onClick={(b)=>{props.onGridItemClick(1,b)}} item={props.craftGrid[1]}/>
      <GridItem onClick={(b)=>{props.onGridItemClick(2,b)}} item={props.craftGrid[2]}/>
      <GridItem onClick={(b)=>{props.onGridItemClick(3,b)}} item={props.craftGrid[3]}/>
      <GridItem onClick={(b)=>{props.onGridItemClick(4,b)}} item={props.craftGrid[4]}/>
      <GridItem onClick={(b)=>{props.onGridItemClick(5,b)}} item={props.craftGrid[5]}/>
      <GridItem onClick={(b)=>{props.onGridItemClick(6,b)}} item={props.craftGrid[6]}/>
      <GridItem onClick={(b)=>{props.onGridItemClick(7,b)}} item={props.craftGrid[7]}/>
      <GridItem onClick={(b)=>{props.onGridItemClick(8,b)}} item={props.craftGrid[8]}/>
  </div>
);

export default CraftingGrid;
