import React, { FC } from 'react';
import styles from './InventoryItem.module.css';
import InventoryItemData from '../../data/InventoryItemData';
import Twemoji from 'react-twemoji';

interface InventoryItemProps {
  itemData: InventoryItemData;
  onClick: (item : InventoryItemData) => void;
  selected: boolean;
}

const InventoryItem: FC<InventoryItemProps> = (props) => (
  <article className={'InventoryItem'+(props.selected?" selected" : "")}>
    <div className='icon inset' style={{"--emoji":props.itemData.emojis.length} as React.CSSProperties} onClick={(e)=>{
      e.stopPropagation();
      props.onClick(props.itemData)
    }}>
      {props.itemData.emojis.map((emoji) => (
        <Twemoji><span key={emoji} className='twemoji'>{emoji}</span></Twemoji>
      ))}
    
    </div>
    <div className='namebox'>
      <span className='name'>{props.itemData.name}</span>
    </div>
    <span className='tooltip'>{props.itemData.name}</span>
  </article>
);

export default InventoryItem;
