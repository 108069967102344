import React, { FC } from 'react';
import styles from './CraftingInventory.module.css';
import InventoryItem from '../InventoryItem/InventoryItem';
import { useInventory } from '../../data/InventoryManager';
import InventoryItemData from '../../data/InventoryItemData';

interface CraftingInventoryProps {
  currentlySelectedItem: InventoryItemData | null;
  setCurrentlySelectedtem?: (item: InventoryItemData) => void;
}

const CraftingInventory: FC<CraftingInventoryProps> = (props) => (
  <aside className='inventory inset'>
    {useInventory().inventory.map((item) => (
      <InventoryItem itemData={item} key={item.name} selected={props.currentlySelectedItem?.name === item.name} onClick={(sitem)=>{
        if (props.setCurrentlySelectedtem!=null) // check to make sure the function is defined
        {
          props.setCurrentlySelectedtem(sitem);
        }
      }} />
    ))}
  </aside>
);

export default CraftingInventory;
