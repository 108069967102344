import React, { FC } from 'react';
import styles from './CraftingInterface.module.css';
import CraftingGrid from '../CraftingGrid/CraftingGrid';
import { ChevronRight } from 'react-bootstrap-icons';
import GridItem from '../GridItem/GridItem';
import InventoryItemData from '../../data/InventoryItemData';

interface CraftingInterfaceProps {
	craftGrid: (InventoryItemData | null)[];
	onGridItemClick: (index : number,button : number) => void;
	craftedItem: InventoryItemData | null;
	clearGrid: () => void;
	craftItem: () => void;
	isLoading: boolean;
}

const CraftingInterface: FC<CraftingInterfaceProps> = (props) => (
	<main>
		<div className='CraftingInterface window'>
			<div className='input'>
				<CraftingGrid craftGrid={props.craftGrid} onGridItemClick={props.onGridItemClick}/>

			</div>

			<ChevronRight size={50} className='carrow'/>

			<div className='output'>
				<GridItem item={props.craftedItem} loading={props.isLoading}/>
				<span className='CraftedItemName inset'>{props.craftedItem?.name}</span>
				<div className='buttons'>
					<button onClick={props.clearGrid}>Clear</button>
					<button onClick={props.craftItem}>Craft!</button>
				</div>
			</div>
		</div>
	</main>
);

export default CraftingInterface;
