import React, { FC } from 'react';
import styles from './GridItem.module.css';
import InventoryItemData from '../../data/InventoryItemData';
import Twemoji from 'react-twemoji';

interface GridItemProps {
  item: InventoryItemData | null;
  onClick?: (button : number) => void;
  loading?: boolean;
}

function clickHandle(e : React.MouseEvent<HTMLDivElement, MouseEvent>,props : GridItemProps) {
  e.stopPropagation();
  e.preventDefault();
  if (props.onClick) {
    props.onClick(e.nativeEvent.button);
  }
}

const GridItem: FC<GridItemProps> = (props) => (
  <div className={'GridItem inset'+(props.loading?" loading":"")} style={{"--emoji":props.item?.emojis.length} as React.CSSProperties} 
  onClick={(e)=>{clickHandle(e,props)}}
  onContextMenu={(e)=>{clickHandle(e,props)}}
  onMouseDown={(e)=>{
    if (e.nativeEvent.button === 1) {
      e.preventDefault();
      clickHandle(e,props);
    }
  }}
  >
    <div className='icon'>
        {props.item?.emojis.map((emoji) => (
          <Twemoji><span key={emoji} className='twemoji'>{emoji}</span></Twemoji>
        ))}
      </div>
  </div>
);

export default GridItem;
