import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header/Header';
import CraftingInterface from './components/CraftingInterface/CraftingInterface';
import CraftingInventory from './components/CraftingInventory/CraftingInventory';
import Footer from './components/Footer/Footer';
import CurrentlySelectedItem from './components/CurrentlySelectedItem/CurrentlySelectedItem';
import InventoryItemData from './data/InventoryItemData';
import CraftingApi from './api/CraftingApi';
import { useInventory } from './data/InventoryManager';
//import useSound from 'use-sound';

//import sfxPickup from './sfx/pickup.mp3';
//import sfxDrop from './sfx/drop.mp3';

function App() {
	//const [playPickup] = useSound(sfxPickup);
	//const [playDrop] = useSound(sfxDrop);
	const [currentlySelectedItem, setCurrentlySelectedItem] = React.useState<InventoryItemData | null>(null);
	const [craftedItem, setCraftedItem] = React.useState<InventoryItemData | null>(null);
	const [craftGrid, setCraftGrid] = React.useState<(InventoryItemData | null)[]>([null,null,null,null,null,null,null,null,null]);
	const [isCrafting, setIsCrafting] = React.useState(false);
	const inventory = useInventory();

	function setGridItem(index: number, item: InventoryItemData | null) {
		const newGrid = [...craftGrid];
		newGrid[index] = item;
		setCraftGrid(newGrid);
	}

	function clearGrid() {
		setCraftGrid([null,null,null,null,null,null,null,null,null]);
		setCraftedItem(null);
	}

	function craftItem() {
		// try to craft the item
		setCraftedItem(null);

		// get all elements of craftGrid that are not null
		const items = craftGrid.filter((item)=>item !== null) as InventoryItemData[];

		// if no items, or if there's only one item, don't do anything
		if (items.length === 0 || items.length === 1) {
			return;
		}

		setIsCrafting(true);
		CraftingApi.craftItems(craftGrid).then((response)=>{
			setIsCrafting(false);
			const item = response.data as InventoryItemData;
			if (item.emojis==null || item.name==null) {
				return
			}
			setCraftedItem(item);
			inventory.addItem(item);
		}).catch((error)=>{
			setIsCrafting(false);
		});
		//setCraftedItem(new InventoryItemData("Test Item",['❌','✨','🔥']));
	}

	function setSelectedItem(item: InventoryItemData | null) {
		/*if (item) {
			playPickup();
		}else{
			playDrop();
		}*/
		setCurrentlySelectedItem(item);
	
	}

	function onGridItemClick(index: number, button:number) {
		if (button === 0 || button === 2){
			if (currentlySelectedItem) {
				setGridItem(index, currentlySelectedItem);
				if (button === 0) { // left click to place item, right click to place but keep item selected
					setSelectedItem(null);
				}
			}else{
				setSelectedItem(craftGrid[index]);
				setGridItem(index, null);
			}
		}else if (button === 1) {// copy item with middle click
			setSelectedItem(craftGrid[index]);
		}
		
	}

	return (
		<div className="App" onClick={()=>{
			if (currentlySelectedItem) {
				setSelectedItem(null);
			}
		}}>
			<Header/>
			<CraftingInterface craftGrid={craftGrid} onGridItemClick={onGridItemClick} craftedItem={craftedItem} clearGrid={clearGrid} craftItem={craftItem} isLoading={isCrafting}/>
			<CraftingInventory setCurrentlySelectedtem={setSelectedItem} currentlySelectedItem={currentlySelectedItem} />
			<Footer/>
			<CurrentlySelectedItem item={currentlySelectedItem}/>
		</div>
  );
}

export default App;
