import React, { FC, useEffect, useState } from 'react';
import styles from './CurrentlySelectedItem.module.css';
import InventoryItemData from '../../data/InventoryItemData';
import Twemoji from 'react-twemoji';

interface CurrentlySelectedItemProps {
  item : InventoryItemData | null;
}

const CurrentlySelectedItem: FC<CurrentlySelectedItemProps> = (props) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      setPosition({ x: event.clientX, y: event.clientY });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className='CurrentlySelectedItem window' style={{ top: position.y, left: position.x,display: props.item ? 'block' : 'none'}}>
      <div className='icon inset' style={{"--emoji":props.item?.emojis.length} as React.CSSProperties}>
        {props.item?.emojis.map((emoji) => (
          <Twemoji><span key={emoji} className='twemoji'>{emoji}</span></Twemoji>
        ))}
      </div>
    </div>
  );
};

export default CurrentlySelectedItem;
